import { graphql } from 'gatsby'
import React from 'react'
import IframeResizer from 'iframe-resizer-react'
import { get } from 'lodash'

export const WebformEmbedParagraph = ({ paragraph }) => {
  const iframeCode = get(paragraph, 'field_webform.iframe_code', undefined)
  if (!iframeCode) {
    return null
  }

  const status = get(paragraph, 'field_webform.status', 'closed')
  if (status !== 'open') {
    return null
  }

  const iframeSrc = iframeCode.match(/iframe src\s*=\s*["\']?([^"\'\s>]+)["\']?/)
  if (!iframeSrc || undefined === iframeSrc[1]) {
    return null
  }

  return (
    <div className="container featured-content">
      <IframeResizer src={iframeSrc[1]} style={{ width: '1px', minWidth: '100%', border: 0 }} />
    </div>
  )
}

export const ParagraphWebformEmbedFragment = graphql`
  fragment ParagraphWebformEmbedFragment on paragraph__webform_embed {
    type: __typename
    id
    drupal_internal__id
    field_webform {
      iframe_code
      status
    }
  }
`
