import React from 'react'
import { FeaturedContentItemsParagraph } from 'components/paragraph/featured-content-items-paragraph'
import { IconCollectionParagraph } from 'components/paragraph/icon-collection-paragraph'
import { WebformEmbedParagraph } from 'components/paragraph/webform-embed-paragraph'

const components = {
  paragraph__featured_content_collection: FeaturedContentItemsParagraph,
  paragraph__icon_collection: IconCollectionParagraph,
  paragraph__webform_embed: WebformEmbedParagraph,
}

export const getParagraph = (paragraph) => {
  if (!paragraph.hasOwnProperty('id')) {
    return null
  }

  if (components.hasOwnProperty(paragraph.type)) {
    const ParagraphComponent = components[paragraph.type]

    return <ParagraphComponent key={paragraph.id} paragraph={paragraph} />
  }

  return <p key={paragraph.id}>Unknown type {paragraph.type}</p>
}
