import { get, has } from 'lodash'
import React from 'react'
import { graphql } from 'gatsby'
import { siteMetadata } from '../../../gatsby-config'
import BackgroundImage from 'gatsby-background-image'
import { isUrlAbsolute } from '../../util/util'

const NODE_BUNDLES = {
  node__magazine_article: {
    featured_image_path: 'relationships.field_page_banner_image.localFile',
  },
  node__news_item: {
    featured_image_path: 'relationships.field_teaser_image.localFile',
  },
  node__page: {
    featured_image_path: 'relationships.field_page_banner_image.localFile',
  },
  node__video: {
    featured_image_path: 'relationships.field_teaser_image.localFile',
  },
  node__foundation_page: {
    featured_image_path: 'relationships.field_banner.relationships.field_slide_image.localFile',
  },
}

const FeaturedNode = ({ node, ctaLabel, ctaLink, className }) => {
  if (!ctaLink && !node) {
    return null
  }

  // If ctaLink is not passed, and the node is given, set the drupal node as
  // the target link.
  if (!ctaLink) {
    ctaLink = ''
    if (node && has(node, 'path.alias')) {
      ctaLink = node.path.alias
    }
  }

  if (ctaLink && !isUrlAbsolute(ctaLink)) {
    ctaLink = get(siteMetadata, 'drupalUrl') + ctaLink
  }

  // @Todo: Support cases where a manual image is passed.
  let title = undefined
  if (node) {
    title = node.title
    let featured_image_path = undefined
    const bundle = node.type
    if (NODE_BUNDLES[bundle]) {
      featured_image_path = NODE_BUNDLES[bundle].featured_image_path
    }

    if (!!featured_image_path && !!has(node, featured_image_path)) {
      let featured_image = get(node, featured_image_path)
      return (
        <div className={className}>
          <BackgroundImage
            Tag="div"
            className="card h-100"
            key={node.drupal_internal__nid}
            fluid={featured_image.childImageSharp.fluid}
          >
            <div className="card-body">
              {title && <div className="card-title h5">{title}</div>}
              <a className="btn btn-outline-light" href={ctaLink} role="button">
                {ctaLabel}
              </a>
            </div>
          </BackgroundImage>
        </div>
      )
    }
  }

  return (
    <div className={className}>
      <div className="card h-100 bg-primary rounded-0 border-0">
        <div className="card-body">
          {title && <div className="card-title h5">{title}</div>}
          <a className="btn btn-outline-light" href={ctaLink} role="button">
            {ctaLabel}
          </a>
        </div>
      </div>
    </div>
  )
}

export const featuredMagazineArticleFragment = graphql`
  fragment FeaturedMagazineArticleFields on node__magazine_article {
    title
    drupal_internal__nid
    field_display_title
    path {
      alias
    }
    field_page_banner_image {
      title
      alt
    }
    relationships {
      field_page_banner_image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 491) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export const featuredNewsItemFragment = graphql`
  fragment FeaturedNewsItemFields on node__news_item {
    title
    drupal_internal__nid
    field_display_title
    path {
      alias
    }
    field_teaser_image {
      title
      alt
    }
    relationships {
      field_teaser_image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 491) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export const featuredPageFragment = graphql`
  fragment FeaturedPageFields on node__page {
    title
    drupal_internal__nid
    field_display_title
    path {
      alias
    }
    field_page_banner_image {
      title
      alt
    }
    relationships {
      field_page_banner_image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 491) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export const featuredVideoFragment = graphql`
  fragment FeaturedVideoFields on node__video {
    title
    drupal_internal__nid
    field_display_title
    path {
      alias
    }
    field_teaser_image {
      title
      alt
    }
    relationships {
      field_teaser_image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 491) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export const featuredFoundationPageFragment = graphql`
  fragment FeaturedFoundationPageFields on node__foundation_page {
    title
    drupal_internal__nid
    path {
      alias
    }
    relationships {
      field_banner {
        field_slide_cta {
          title
          url
        }
        field_slide_image {
          title
          alt
        }
        drupal_internal__id
        relationships {
          field_slide_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 491) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default FeaturedNode
