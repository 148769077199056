import { graphql } from 'gatsby'
import { get, has } from 'lodash'
import React from 'react'
import './icon-collection-paragraph.scss'

export const IconItemParagraph = ({ paragraph }) => {
  if (!has(paragraph, 'relationships.field_image.relationships.field_media_svg.localFile')) {
    return null
  }

  const image = get(paragraph, 'relationships.field_image.relationships.field_media_svg.localFile')
  if (!!image && !!image.publicURL) {
    const iconItemUrl = get(paragraph, 'field_link.url')
    if (iconItemUrl) {
      return (
        <div className="col-sm-3 paragraph-icon-item">
          <div className="paragraph-icon-image">
            <a href={iconItemUrl}>
              <img src={image.publicURL} alt={image.name} />
            </a>
          </div>
          <div className="paragraph-icon-heading">
            <a href={iconItemUrl}>{get(paragraph, 'field_title', '')}</a>
          </div>
        </div>
      )
    }

    return (
      <div className="col-sm-3 paragraph-icon-item">
        <div className="paragraph-icon-image">
          <img src={image.publicURL} alt={image.name} />
        </div>
        <div className="paragraph-icon-heading">{get(paragraph, 'field_title', '')}</div>
      </div>
    )
  }

  return null
}

export const IconCollectionParagraph = ({ paragraph }) => {
  const iconItems = get(paragraph, 'relationships.field_icon_collection_items')
  if (!iconItems) {
    return null
  }

  return (
    <section className="container featured-content paragraph--icon-collection">
      <div className="row text-center">
        {iconItems.map((paragraph) => (
          <IconItemParagraph paragraph={paragraph} key={paragraph.drupal_internal__id} />
        ))}
      </div>
    </section>
  )
}

export const ParagraphIconCollectionFragment = graphql`
  fragment ParagraphIconCollectionFragment on paragraph__icon_collection {
    type: __typename
    id
    relationships {
      field_icon_collection_items {
        type: __typename
        drupal_internal__id
        field_title
        field_link {
          url
        }
        relationships {
          field_image {
            name
            relationships {
              field_media_svg {
                localFile {
                  name
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`
