import FeaturedNode from 'components/node/featured-node'
import { graphql } from 'gatsby'
import React from 'react'
import { get, has } from 'lodash'

export const FeaturedContentItem = ({ paragraph }) => {
  const contentNode = get(paragraph, 'relationships.field_node', undefined)
  let ctaLink = undefined
  if (has(paragraph, 'field_link.url')) {
    ctaLink = get(paragraph, 'field_link.url')
  }

  return (
    <FeaturedNode
      node={contentNode}
      ctaLabel={get(paragraph, 'field_title', 'Read More')}
      ctaLink={ctaLink}
      className="col px-1"
    />
  )
}

export const FeaturedContentItemsParagraph = ({ paragraph }) => {
  const featuredContentItems = get(paragraph, 'relationships.field_featured_content_items', undefined)
  if (!featuredContentItems) {
    return null
  }

  return (
    <section className="container featured-content">
      <div className="row row-cols-1 row-cols-md-3 text-center text-light">
        {featuredContentItems.map((paragraph) => (
          <FeaturedContentItem
            paragraph={paragraph}
            className="col px-1"
            key={paragraph.drupal_internal__id}
          />
        ))}
      </div>
    </section>
  )
}

export const ParagraphFeaturedContentItemsFragment = graphql`
  fragment ParagraphFeaturedContentItemsFragment on paragraph__featured_content_collection {
    type: __typename
    id
    relationships {
      field_featured_content_items {
        type: __typename
        drupal_internal__id
        field_title
        field_link {
          url
        }
        relationships {
          type: __typename
          field_node {
            type: __typename
            ... on node__magazine_article {
              ...FeaturedMagazineArticleFields
            }
            ... on node__news_item {
              ...FeaturedNewsItemFields
            }
            ... on node__page {
              ...FeaturedPageFields
            }
            ... on node__video {
              ...FeaturedVideoFields
            }
            ... on node__foundation_page {
              ...FeaturedFoundationPageFields
            }
          }
        }
      }
    }
  }
`
